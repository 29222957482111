<template>
  <div class="academic-years">
    <lenon-list-page
      title="Old Students"
      :columns="columns"
      :rows="oldStudents"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      :enable-multi-select="true"
      :show-profile-photo="true"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <lenon-select
            v-model="selectedClassId"
            :options="classes"
            label-name="name"
            value-name="id"
            placeholder="Select Batch"
            class="mr-2"
            @input="fetchStudents"
          />
          <!--          <lenon-button-->
          <!--            icon="MessageCircleIcon"-->
          <!--            label="Notify"-->
          <!--            tool-tip-text="Notify parents of your old students"-->
          <!--            class="mr-1"-->
          <!--          />-->
        </div>
      </template>
      <template #gender="{row}">
        <b-badge
          :icon-only="true"
          :variant="row.item.gender===0?'light-primary':'light-success'"
        >
          {{ row.item.gender===0?'Male':'Female' }}
        </b-badge>
      </template>
      <template #full_name="{row}">
        {{ row.item.first_name }} {{ row.item.middle_name }} {{ row.item.last_name }}
      </template>
      <!--      <template #actions="{row}">-->
      <!--        <lenon-dropdown>-->
      <!--          <b-dropdown-item-->
      <!--            @click="showEditModal(row.item)"-->
      <!--          >-->
      <!--            <feather-icon-->
      <!--              icon="Edit2Icon"-->
      <!--              class="mr-50"-->
      <!--            />-->
      <!--            <span> Edit</span>-->
      <!--          </b-dropdown-item>-->
      <!--          <b-dropdown-item-->
      <!--            v-if="row.status !==1"-->
      <!--            @click="confirmDelete(row.item)"-->
      <!--          >-->
      <!--            <feather-icon-->
      <!--              icon="XIcon"-->
      <!--              class="mr-50"-->
      <!--            />-->
      <!--            <span> Delete</span>-->
      <!--          </b-dropdown-item>-->
      <!--        </lenon-dropdown>-->
      <!--      </template>-->
      <!--      <template #status="{row}">-->
      <!--        <lenon-button-->
      <!--          :icon-only="true"-->
      <!--          :variant="row.item.status?'flat-success':'flat-danger'"-->
      <!--          :icon="row.item.status?'UserCheckIcon':'UserXIcon'"-->
      <!--          :tool-tip-text="row.item.status?'Active, click to set inactive':'Inactive, click to set active'"-->
      <!--          @onClick="toggleStudentStatus(row.item)"-->
      <!--        />-->
      <!--      </template>-->
      <!--      <template #gender="{row}">-->
      <!--        <b-badge-->
      <!--          :icon-only="true"-->
      <!--          :variant="row.item.gender===0?'light-primary':'light-success'"-->
      <!--        >-->
      <!--          {{ row.item.gender===0?'Male':'Female' }}-->
      <!--        </b-badge>-->
      <!--      </template>-->
    </lenon-list-page>
  </div>
</template>

<script>
import {
  BAvatar, BDropdownItem, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import StudentRegistration from '@/views/students/registration/Registration.vue'
import { FETCH_STUDENTS_Q, FETCH_BATCHES_Q } from '@/graphql/queries'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import confirm from '@/lenon/mixins/confirm'
import { DELETE_STUDENT_M, TOGGLE_STUDENT_STATUS_M } from '@/graphql/mutations'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import LenonModal from '@/lenon/components/LenonModal.vue'

export default {
  name: 'StudentsList',
  components: {
    LenonModal,
    LenonMultiSelect,
    LenonDropdown,
    StudentRegistration,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BAvatar,
    BDropdownItem,
    BBadge,
    ValidationObserver,
    BFormCheckbox,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      selectedClassId: null,
      studentsModalOpened: false,
      tableLoading: false,
      idCardModalOpened: false,
      generatingIds: false,
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'student_id',
          label: 'Student ID',
        },
        {
          key: 'full_name',
          label: 'Full Name',
        },
        {
          key: 'gender',
          label: 'Gender',
        },
        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'address',
          label: 'Address',
        },
        // {
        //   key: 'status',
        //   label: 'Status',
        // },
        // {
        //   key: 'batch_date',
        //   label: 'Completion Date',
        // },
      ],
    }
  },
  computed: {
    oldStudents() {
      return this.$store.getters['students/oldStudents']
    },
    classes() {
      return this.$store.getters['students/batches']
    },
    classId() {
      return this.$store.getters['students/selectedBatchId']
    },
  },
  mounted() {
    this.selectedClassId = this.classId
    this.fetchBatches()
  },
  methods: {
    showPreviewModal(student) {

    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'student':
          this.studentsModalOpened = false
          break
      }
    },
    // eslint-disable-next-line camelcase
    fetchStudents(classId) {
      this.$store.commit('students/setSelectedBatch', classId)
      if (!classId) {
        this.$store.commit('students/setOldStudents', [])
        return
      }
      this.tableLoading = true
      this.$apollo.query({
        query: FETCH_STUDENTS_Q,
        variables: { class_id: classId },
      })
        .then(res => {
          if (!res.errors && res.data) {
            this.$store.commit('students/setOldStudents', res.data.students)
          }

          this.tableLoading = false
        })
        .catch(err => {
          this.tableLoading = false
        })
    },
    fetchBatches() {
      this.$apollo.query({ query: FETCH_BATCHES_Q })
        .then(res => {
          this.$store.commit('students/setBatches', res.data.batches)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    deleteStudent(student) {
      if (!student) {
        this.showInfo('Please select a student')
        return
      }
      this.tableLoading = true
      this.$apollo.mutate({
        mutation: DELETE_STUDENT_M,
        variables: { id: student.id },
      })
        .then(() => {
          this.tableLoading = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('students/removeStudent', student.id)
        })
        .catch(() => {
          this.showError('Failed to delete student')
          this.tableLoading = false
        })
    },

  },
}
</script>
