export default {
  data() {
    return {
      relations: [
        {
          label: 'Mother',
          value: 0,
        },
        {
          label: 'Father',
          value: 1,
        },
        {
          label: 'Auntie',
          value: 2,
        },
        {
          label: 'Uncle',
          value: 3,
        },
        {
          label: 'Brother',
          value: 4,
        },
        {
          label: 'Sister',
          value: 5,
        },
        {
          label: 'Grand Mother',
          value: 6,
        },
        {
          label: 'Grand Father',
          value: 7,
        },
        {
          label: 'Guardian',
          value: 8,
        },
      ],
    }
  },
}
