export default {
  methods: {
    urlToFile(url, type) {
      const blobBin = atob(url.split(',')[1])
      const array = []
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      const file = new Blob([new Uint8Array(array)], { type: `image/${type}` })
      return file
    },
  },
}
